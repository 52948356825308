//import 'slick-carousel';
export default {
  init() {
    console.log('home');

    /* Useful function for adding the em_ajax flag to a url, regardless of querystring format */
    var em_ajaxify = function(url) {
      if (url.search('em_ajax=0') != -1) {
        url = url.replace('em_ajax=0', 'em_ajax=1');
      } else if (url.search(/\?/) != -1) {
        url = url + '&em_ajax=1';
      } else {
        url = url + '?em_ajax=1';
      }
      return url;
    };

    //un clic toggle responsive
    $('#toggleUnclick').click(function() {
      $('#un-clic__menu').toggleClass('visible');
      $('#toggleUnclick').toggleClass('rotate');
    });

    /* Calendar AJAX */
    $('.em-calendar-wrapper a').unbind('click');
    $('.em-calendar-wrapper a').undelegate('click');
    $('.em-calendar-wrapper').delegate(
      'a.em-calnav, a.em-calnav',
      'click',
      function(e) {
        e.preventDefault();
        $(this)
          .closest('.em-calendar-wrapper')
          .prepend('<div class="loading" id="em-loading"></div>');
        var url = em_ajaxify($(this).attr('href'));
        $(this)
          .closest('.em-calendar-wrapper')
          .load(url, function() {
            $(this).trigger('em_calendar_load');
          });
      }
    );
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
