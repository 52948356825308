import 'accordion-js';

export default {
  init() {
    if (jQuery('#submenu > li').hasClass('current_page_item')) {
      jQuery('#subsubmenu').appendTo('#menu-lvl3 .current_page_item');
    }

    if (document.querySelector('.accordion-container') !== null) {
      const accordion = new Accordion('.accordion-container');
    }

    const emptyRight =
      $('.page-int__right').val() === undefined
        ? true
        : $('.page-int__right')
            .html()
            .trim().length == 0;

    const emptyLeft =
      $('.page-int__left').val() === undefined
        ? true
        : $('.page-int__left')
            .html()
            .trim().length == 0;

    if (emptyRight && emptyLeft) {
      $('main').addClass('noRight-noLeft');
    } else if (emptyRight) {
      $('main').addClass('noRight');
    } else if (emptyLeft) {
      $('main').addClass('noLeft');
    }
    // console.log(emptyRight);
    // console.log(emptyLeft);

    $(' #submit-filter').on('click', function() {
      console.log('click');

      var filter = $('#filter');
      var loading = $('.load-bar');
      $.ajax({
        url: filter.attr('action'),
        data: filter.serialize(),
        type: filter.attr('method'),
        beforeSend: function(xhr) {
          $('.events').html('');
          loading.toggleClass('invisible');
        },
        success: function(data) {
          setTimeout(function() {
            loading.toggleClass('invisible');
            $('.events').html(data);
          }, 1000);
        }
      });
      return false;
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};
