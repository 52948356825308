// Styles SCSS
import '../sass/app.scss';

// jQuery
import jQuery from 'jquery';

window.$ = $;

// Router
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import page from './routes/page';
import phototheque from './routes/phototheque';
//import associations from './routes/associations';

// SVG polyfiil
import 'svgxuse';

// LazyLoad
import lazyLoadInit from './lazyload-init';
lazyLoadInit();

// pushbar
import Pushbar from './util/pushbar';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  /** page */
  page,

  phototheque
  //associations
});

/** Load Events */
jQuery(document).ready(() => {
  const pushbar = new Pushbar({
    blur: true,
    overlay: true
  });
  routes.loadEvents();
});
