import 'jquery.marquee';
export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('common');
    jQuery(document).ready(() => {
      $('.ticker').marquee({
        duration: 13000,
        delayBeforeStart: 0,
        direction: 'left',
        duplicated: false,
        pauseOnHover: true
      });
    });

    // Search animation
    $('.control').click(function() {
      $('body').addClass('search-active');
      $('#recherche').focus();
    });

    $('.icon-close').click(function() {
      $('body').removeClass('search-active');
    });
    $(document).keyup(function(e) {
      if (e.keyCode === 27) {
        $('body').removeClass('search-active');
      }
    });

    // Gtranslate
    jQuery('.translate option:contains("Select Language")').text('langues');
    jQuery('.translate option:contains("English")').text('EN');
    jQuery('.translate option:contains("French")').text('FR');
    jQuery('.translate option:contains("Norwegian")').text('NW');
    jQuery('.translate option:contains("FR")').attr('selected', true);

    function resize() {
      const offSetImgs = document.getElementsByClassName('bgOffSet');
      const w = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
      const containerWidth = document.getElementsByClassName('container')[0]
        .clientWidth;
      let offSetWidth = (w - containerWidth) / 2 - 10;

      for (let i = 0; i < offSetImgs.length; i++) {
        if (i > 0) {
          offSetWidth -= 15;
        }
        offSetImgs[i].setAttribute('style', `width: ${offSetWidth}px;`);
      }
    }

    resize();

    window.onresize = resize;

    function nightMode() {
      if (localStorage.getItem('stylesheet')) {
        localStorage.clear();
        document.getElementById('night-css').setAttribute('href', '');
      } else {
        localStorage.clear();
        document.getElementById('grey-css').setAttribute('href', '');
        localStorage.setItem(
          'stylesheet',
          '/wp-content/themes/montlucon/night.css'
        );
        document
          .getElementById('night-css')
          .setAttribute('href', localStorage.getItem('stylesheet'));
      }
    }

    function greyMode() {
      if (localStorage.getItem('stylesheetGrey')) {
        localStorage.clear();
        document.getElementById('grey-css').setAttribute('href', '');
      } else {
        localStorage.clear();
        document.getElementById('night-css').setAttribute('href', '');
        localStorage.setItem(
          'stylesheetGrey',
          '/wp-content/themes/montlucon/grey.css'
        );
        document
          .getElementById('grey-css')
          .setAttribute('href', localStorage.getItem('stylesheetGrey'));
      }
    }

    document.getElementById('night-mode').addEventListener('click', nightMode);
    document.getElementById('grey-mode').addEventListener('click', greyMode);

    $('.menu-accordion .depliable').on('click', function() {
      console.log('click');

      if (
        jQuery(this)
          .parent()
          .hasClass('selected')
      ) {
        jQuery(this)
          .parent()
          .removeClass('selected');
        $(this)
          .parent()
          .children('.sub-menu')
          .stop(true, true)
          .slideUp();
      } else {
        jQuery(this)
          .parent()
          .addClass('selected');
        $(this)
          .parent()
          .children('.sub-menu')
          .stop(true, true)
          .slideDown();
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};
